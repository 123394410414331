import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderAnimationService {

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  loadingCount: number = 0;

  loaderOn() {
    this.loadingCount += 1;
    this.check();
  }

  loaderOff() {
    this.loadingCount -= 1;
    this.check();
  }

  loaderTerminate() {
    this.loadingCount = 0;
    this.check();
  }

  check() {
    if (this.loadingCount > 0) {
      this.isLoading.next(true)
    } else {
      this.isLoading.next(false)
    }
  }
}
